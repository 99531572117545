@import './vars.scss';

@mixin for-size($size) {
	@if $size == phone {
		@media (max-width: $phone) {
			@content;
		}
	} @else if $size == tablet {
		@media (min-width: $tablet) {
			@content;
		}
	} @else if $size == desktop-up {
		@media (min-width: $desktop) {
			@content;
		}
	} @else if $size == big-desktop-up {
		@media (min-width: $big-d) {
			@content;
		}
	}
}

@mixin desktop-down {
	@media (max-width: 1199px) {
		@content;
	}
}

@mixin gold-gradient {
	background: rgb(237, 182, 88);
	background: linear-gradient(130deg, $gold-light 0%, $gold-dark 100%);
}

@mixin white-transparent-gradient {
	background: rgb(255, 255, 255);
	background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.2) 100%);
}

@mixin layer-shadow {
	box-shadow: 0 5px 15px 0px rgba($color: #000000, $alpha: 0.2);
}
