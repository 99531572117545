@import 'src/style/vars';
@import 'src/style/mixins';

#Landing_page {
	background-color: $dark;
	min-height: 100vh;
	position: relative;
	// Animation for menu
	// transform: translate3d(60%,0,0) scale3d(.85,.85,1);
}

.Landing_page__background {
	text-align: center;
	position: relative;
}

.Landing_page__background-image {
	width: 100%;
	height: 100%;
	background-image: url('/mac-flipped-16-9.jpg');
	background-position: center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: cover; /* Resize the background image to cover the entire container */
	text-align: center;
	position: absolute;
}

.Landing_page__header {
	font-size: 1.4rem !important;
	margin: 0px !important;
	text-align: center;
	color: $gold-light !important;
	transform: translateY(5rem);

	span {
		writing-mode: vertical-rl;
		text-orientation: upright;
	}

	@include for-size(phone) {
		transform: translateY(2rem);
		font-size: 1.2rem !important;
	}
}

.Landing_page__header--second {
	transform: translate(0px, 6.65rem);
	@include for-size(phone) {
		transform: translate(0px, 6.1rem);
	}
}

.Landing_page__logo {
	margin: auto;
	margin-top: 25rem;
	width: 430px;
	max-width: 430px;
	position: relative;

	@include for-size(phone) {
		margin-top: 18rem;
		width: 100%;
	}
}

.my-work {
	padding-bottom: 150px;
}

.my-work__header {
	border-top: 1px solid $gold-light;
	height: 2px;
	@include gold-gradient();
	position: relative;
	margin: 50px auto;
}

.my-work__header__text {
	position: absolute;
	color: $gold-light !important;
	font-weight: 300 !important;
	background-color: $dark;
	width: 300px;
	text-align: center;
	display: block;
	top: -12px;
	left: calc(50% - 150px);
}

.curtain {
	height: 100vh;
	position: fixed;
	top: 0;
	background-color: $dark;
	z-index: 5;
	pointer-events: none;
}

.curtain-left {
	left: 0px;
	background-color: $gold-light;
}

.curtain-right {
	left: 50%;
	width: 50vw;
	transform: translateX(50vw);
	background-color: $dark-layer;
}
