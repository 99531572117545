@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
#App{overflow-x:hidden}

#Burger{position:fixed;top:50px;right:60px;position:absolute;z-index:100}@media (max-width: 767px){#Burger{top:30px;right:20px}}.Burger__bar{background:#edb658;background:linear-gradient(130deg, #edb658 0%, #bf983d 100%);display:block;height:4px;margin-bottom:5px;position:relative;z-index:1;transform-origin:4px 0px;transition:transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),opacity 0.55s ease}.Burger__bar--1{width:36px}.Burger__bar--2{width:40px}.Burger__bar--3{width:32px}

#Landing_page{background-color:#2d2d2d;min-height:100vh;position:relative}.Landing_page__background{text-align:center;position:relative}.Landing_page__background-image{width:100%;height:100%;background-image:url("/mac-flipped-16-9.jpg");background-position:center;background-repeat:no-repeat;background-size:cover;text-align:center;position:absolute}.Landing_page__header{font-size:1.4rem !important;margin:0px !important;text-align:center;color:#edb658 !important;transform:translateY(5rem)}.Landing_page__header span{-ms-writing-mode:tb-rl;writing-mode:vertical-rl;-webkit-text-orientation:upright;text-orientation:upright}@media (max-width: 767px){.Landing_page__header{transform:translateY(2rem);font-size:1.2rem !important}}.Landing_page__header--second{transform:translate(0px, 6.65rem)}@media (max-width: 767px){.Landing_page__header--second{transform:translate(0px, 6.1rem)}}.Landing_page__logo{margin:auto;margin-top:25rem;width:430px;max-width:430px;position:relative}@media (max-width: 767px){.Landing_page__logo{margin-top:18rem;width:100%}}.my-work{padding-bottom:150px}.my-work__header{border-top:1px solid #edb658;height:2px;background:#edb658;background:linear-gradient(130deg, #edb658 0%, #bf983d 100%);position:relative;margin:50px auto}.my-work__header__text{position:absolute;color:#edb658 !important;font-weight:300 !important;background-color:#2d2d2d;width:300px;text-align:center;display:block;top:-12px;left:calc(50% - 150px)}.curtain{height:100vh;position:fixed;top:0;background-color:#2d2d2d;z-index:5;pointer-events:none}.curtain-left{left:0px;background-color:#edb658}.curtain-right{left:50%;width:50vw;transform:translateX(50vw);background-color:#373737}

.cls-1{fill:url(#linear-gradient)}.cls-2{fill:url(#linear-gradient-2)}.cls-3{fill:#edb658}

.Contact_me__contact:active{background-color:#252525;transform:scale(0.99)}.Contact_me__contact:focus{outline:#edb658 !important}.Contact_me__contact{transition:background 0.2s;display:inline-block;position:absolute;background:none;border:none;color:#edb658;font-size:18px;cursor:pointer;background:#2d2d2d;z-index:1;top:30px;left:60px}.Contact_me__contact span{display:block;padding:20px 50px}.Contact_me__contact span::before,.Contact_me__contact span::after{content:'';width:2px;height:50%;position:absolute;transition:all 0.2s linear;background:#edb658}@media (max-width: 767px){.Contact_me__contact{display:none}}.Contact_me__contact::before,.Contact_me__contact::after{content:'';width:30%;height:2px;position:absolute;transition:all 0.2s linear;background:#edb658}.Contact_me__contact:hover::before,.Contact_me__contact:hover::after{width:100%}.Contact_me__contact:hover span::before{height:100%}.Contact_me__contact:hover span::after{height:100%}.Contact_me__contact::after{right:0;bottom:0;transition-duration:0.2s}.Contact_me__contact span::after{right:0;bottom:0;transition-duration:0.2s}.Contact_me__contact::before{left:0;top:0;transition-duration:0.2s}.Contact_me__contact span::before{left:0;top:0;transition-duration:0.2s}

.scroll-indicator{width:12px;margin:50px auto 0px auto;position:relative;opacity:0.5}.scroll-indicator::before,.scroll-indicator::after{content:'';display:block;margin-left:auto;margin-right:auto}.scroll-indicator::before{width:12px;height:12px;border-radius:10px;border:1px solid #fff;-webkit-animation:dot 3s infinite ease-in-out;animation:dot 3s infinite ease-in-out}.scroll-indicator::after{width:7px;height:7px;border-right:1px solid #fff;border-bottom:1px solid #fff;transform:rotate(45deg);-webkit-animation:arrow 3s infinite ease-in-out;animation:arrow 3s infinite ease-in-out;-webkit-animation-delay:0.75s;animation-delay:0.75s;opacity:0.25}.scroll-indicator>div{display:block}.scroll-indicator>div::before,.scroll-indicator>div::after{content:'';display:block;margin:5px auto;border-radius:10px;border:1px solid #fff;-webkit-animation:dot 3s infinite ease-in-out;animation:dot 3s infinite ease-in-out}.scroll-indicator>div::before{width:8px;height:8px;-webkit-animation-delay:0.25s;animation-delay:0.25s}.scroll-indicator>div::after{width:6px;height:6px;-webkit-animation-delay:0.5s;animation-delay:0.5s}@media (max-width: 767px){.scroll-indicator{display:none}}@-webkit-keyframes dot{0%{transform:scale(0.75);opacity:0.25}25%{transform:scale(1);opacity:1}100%{transform:scale(0.75);opacity:0.25}}@keyframes dot{0%{transform:scale(0.75);opacity:0.25}25%{transform:scale(1);opacity:1}100%{transform:scale(0.75);opacity:0.25}}@-webkit-keyframes arrow{0%{transform:scale(0.75) rotate(45deg);opacity:0.25}25%{transform:scale(1) rotate(45deg);opacity:1}100%{transform:scale(0.75) rotate(45deg);opacity:0.25}}@keyframes arrow{0%{transform:scale(0.75) rotate(45deg);opacity:0.25}25%{transform:scale(1) rotate(45deg);opacity:1}100%{transform:scale(0.75) rotate(45deg);opacity:0.25}}

.See_more_btn{background:#edb658;background:linear-gradient(130deg, #edb658 0%, #bf983d 100%);height:65px;width:65px;border-radius:50%;display:flex;justify-content:center;align-items:center;box-shadow:0px 8px 15px 0px rgba(237,182,88,0.34);cursor:pointer}.See_more_btn__icon{color:#ffffffb5;transform:translateX(3px)}.See_more_btn:active{transform:scale(0.97)}

.Project_card{position:relative;display:flex;flex-direction:column;align-items:center;height:250px;width:100%;margin-top:40%}.Project_card__background{position:absolute;height:250px;width:100%;display:flex;flex-direction:column;justify-content:flex-end;border-radius:3px;box-shadow:0px 5px 15px 0px rgba(0,0,0,0.2);background-color:#373737;padding:0px 5%}.Project_card__background__btn-container{display:flex;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;justify-content:center;align-items:center;margin-bottom:15px;margin-left:auto;margin-right:auto}@media (max-width: 1199px){.Project_card__background__btn-container{position:absolute;right:20px;bottom:20px;margin-bottom:0px}.Project_card__background__btn-container .See_more_btn{width:40px;height:40px}}.Project_card__background__header{padding-bottom:20px !important;color:rgba(255,255,255,0.6) !important;margin-bottom:0px !important;width:-webkit-fit-content !important;width:-moz-fit-content !important;width:fit-content !important}.Project_card__background__header div{color:rgba(255,255,255,0.6) !important}.Project_card__background__text{transform:scale(0);width:50%;height:200px;color:rgba(255,255,255,0.6) !important;overflow-y:auto;font-size:16px !important}.Project_card__background__link{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;position:absolute !important;left:20px;bottom:140px;opacity:0;transform:scale(0)}.Project_card__background__link:active{transform:scale(0.99)}.Project_card__image{position:absolute;height:275px;width:275px;max-width:85%;top:-40%;background-position:center;background-repeat:no-repeat;background-size:cover;box-shadow:0px 5px 15px 0px rgba(0,0,0,0.2)}@media (min-width: 1200px){.Project_card__image{max-width:none !important}}

#footer{height:250px;background-color:#373737;display:flex;justify-content:space-evenly;align-items:center;position:relative;padding:env(safe-area-inset-top, 40px) env(safe-area-inset-right, 20px) env(safe-area-inset-bottom, 50px) env(safe-area-inset-left, 20px) !important}.footer-header{color:#edb658 !important}.footer-go-up{position:absolute !important;top:0px !important;transform:translateY(-35%) !important;background-color:#373737 !important;color:#edb658 !important;box-shadow:none !important;transition:all 0.3s;cursor:pointer}.footer-go-up:hover{transform:translateY(-35%) scale(1.1) !important;transition:all 0.3s}.social-icon-list{list-style-type:none;margin:0;padding:0;width:100%;height:45px}.social-icon-list li{cursor:pointer;float:left;padding-right:10px}.social-icon-list li i{box-shadow:none !important;transition:all 0.3s}.social-icon-list li i:hover{transform:scale(1.2)}.facebook-social-icon{color:white !important;background:#00c6ff;background:linear-gradient(to right, #0072ff, #00c6ff)}.github-social-icon{color:white !important;background-color:#333333 !important}.linkedin-social-icon{color:white !important;background-color:#0087be !important}.instagram-social-icon{color:white !important;background:#833ab4;background:linear-gradient(to right, #fcb045, #fd1d1d, #833ab4)}.footer-email-btn{margin-top:20px}.footer-email-btn button{background:linear-gradient(130deg, #edb658 0%, #bf983d 100%) !important}

.Project_card_animated{position:fixed;top:0;left:0;height:100vh;width:100vw;z-index:300;visibility:hidden;opacity:0}.Project_card_animated__background{position:fixed;height:100vh;width:100vw;top:0;left:0;z-index:302;overflow-y:auto;overscroll-behavior-y:none;background:linear-gradient(0deg, #2d2d2d 50%, rgba(45,45,45,0) 100%);transform:translateY(100%);padding-bottom:env(safe-area-inset-bottom)}.Project_card_animated__background__text{position:fixed;top:55%;width:calc(100% - 50px);left:25px;color:rgba(255,255,255,0.6)}.Project_card_animated__background__btn-container{position:fixed;top:45%;right:25px}@media (max-width: 1199px){.Project_card_animated__background__btn-container{position:absolute;right:25px;margin-bottom:0px}.Project_card_animated__background__btn-container .See_more_btn{width:40px;height:40px}}.Project_card_animated__background__header{position:fixed;top:45%;left:25px;color:white !important;margin:0px !important}.Project_card_animated__background__header div{color:rgba(255,255,255,0.6) !important}.Project_card_animated__background__link{position:fixed !important;bottom:80px;width:150px;left:calc(50% - 75px)}.Project_card_animated__image{position:fixed;height:50vh;width:100vw;top:0;left:0;transform:translateY(-100%);z-index:301}

body{font-family:'Roboto', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;overflow-x:hidden;-ms-scroll-chaining:none;overscroll-behavior:none;background-color:#2d2d2d}

