@import 'src/style/vars';
@import 'src/style/mixins';

.Project_card {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 250px;
	width: 100%;
	margin-top: 40%;
}

.Project_card__background {
	position: absolute;
	height: 250px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	border-radius: 3px;
	box-shadow: 0px 5px 15px 0px rgba($color: #000000, $alpha: 0.2);
	background-color: $dark-layer;
	padding: 0px 5%;
}

.Project_card__background__btn-container {
	display: flex;
	width: fit-content;
	height: fit-content;
	justify-content: center;
	align-items: center;
	margin-bottom: 15px; //for the box shadow effect
	margin-left: auto;
	margin-right: auto;

	@include desktop-down {
		position: absolute;
		right: 20px;
		bottom: 20px;
		margin-bottom: 0px;

		.See_more_btn {
			width: 40px;
			height: 40px;
		}
	}
}

.Project_card__background__header {
	// override semantic-ui
	padding-bottom: 20px !important;
	color: $light-text !important;
	margin-bottom: 0px !important;
	width: fit-content !important;

	div {
		color: $light-text !important;
	}
}

.Project_card__background__text {
	transform: scale(0);
	width: 50%;
	height: 200px;
	color: $light-text !important;
	overflow-y: auto;
	font-size: 16px !important;
}

.Project_card__background__link {
	width: fit-content;
	position: absolute !important;
	// left: calc(25% - 36px);
	left: 20px;
	bottom: 140px;
	opacity: 0;
	transform: scale(0);
	// background: rgb(237, 182, 88);
	// background: linear-gradient(130deg, $gold-light 0%, $gold-dark 100%) !important;
}

.Project_card__background__link:active {
	transform: scale(0.99);
}

.Project_card__image {
	position: absolute;
	height: 275px;
	width: 275px;
	max-width: 85%;
	top: -40%;
	background-position: center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: cover; /* Resize the background image to cover the entire container */
	box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);

	@include for-size(big-desktop-up) {
		max-width: none !important;
	}
}
