@import 'src/style/vars';
@import 'src/style/mixins';

.See_more_btn {
	@include gold-gradient();
	height: 65px;
	width: 65px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 8px 15px 0px rgba($color: $gold-light, $alpha: 0.34);
	cursor: pointer;
}

.See_more_btn__icon {
	color: #ffffffb5;
	transform: translateX(3px);
}

.See_more_btn:active {
	transform: scale(0.97);
}
