$grey: #5d676f;
$grey-dark: #46494d;
$dark-layer: #373737;
$dark: #2d2d2d;
$purple: #5d4a5a;
$purple-light: #a06473;
$pink: #dd8673;
$gold-light: #edb658;
$gold-dark: #bf983d;
$lighter-text: rgba(
	$color: #fff,
	$alpha: 0.87
);
$light-text: rgba(
	$color: #fff,
	$alpha: 0.6
);

// Breakpoints from semantic-ui
$phone: 767px;
$tablet: 768px;
$desktop: 992px;
$big-d: 1200px;
